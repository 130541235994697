import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface TimeDisplayProps {
  className?: string;
}

const TimeDisplay: React.FC<TimeDisplayProps> = ({ className }) => {
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLocalTime(dayjs().format('MMMM D, YYYY, HH:mm [UTC]Z'));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <div className={className}>{localTime}</div>;
};

export default TimeDisplay;
