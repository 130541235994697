import { PageLayoutSidebar } from '../PageLayout';
import { SidebarSpaceHierarchy } from './SidebarSpaceHierarchy';
import { useState } from 'react';
import { MenuFoldOutIcon } from '@/components/Icons';
import { CreateSpaceButton } from '@/pages/HomePage/OrganizationPage/Sidebar/CreateSpaceButton';

const SIDEBAR_WIDTH_COLLAPSED = 'w-[56px]';
const SIDEBAR_WIDTH_EXPANDED = 'w-[300px]';

export function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const sidebarClasses = sidebarOpen
    ? `transform transition-transform duration-300 ${SIDEBAR_WIDTH_EXPANDED}`
    : `transform transition-transform duration-300 ${SIDEBAR_WIDTH_COLLAPSED}`;
  return (
    <PageLayoutSidebar className="text-xs text-gray-800 pt-6 flex flex-col border-r border-gray-200">
      <div className={sidebarClasses}>
        {sidebarOpen ? (
          <SidebarSpaceHierarchy
            onSidebarOpenClose={setSidebarOpen}
            className={SIDEBAR_WIDTH_EXPANDED}
          />
        ) : (
          <div className={`flex flex-col justify-between h-[90vh] ${SIDEBAR_WIDTH_COLLAPSED}`}>
            <div className="flex items-center justify-center pt-4">
              <MenuFoldOutIcon
                className="w-4 h-4 text-product-gray900 cursor-pointer mt-[1px]"
                onClick={() => setSidebarOpen(true)}
              />
            </div>
            <div></div>
            <div className={`flex flex-col items-center justify-center ${SIDEBAR_WIDTH_COLLAPSED}`}>
              <div className="w-[32px] bg-gray-200 h-px mb-4 mx-4"></div>
              <CreateSpaceButton data-id="create-new-floor" variant="compact" />
            </div>
          </div>
        )}
      </div>
    </PageLayoutSidebar>
  );
}
