import { SidebarSection } from './SidebarGrouping';
import { CreateSpaceButton } from '@/pages/HomePage/OrganizationPage/Sidebar/CreateSpaceButton';
import { SearchInput } from '../primitives/SearchInput';
import { Icon } from '../primitives/icon/Icon';
import { useSRZId } from '@/hooks';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { gql } from '@apollo/client';
import {
  SidebarFloorItemFloorFragment,
  SidebarRoomItemRoomFragment,
  SidebarSiteItemSiteFragment,
  SidebarBuildingItemBuildingFragment,
  SidebarZoneItemZoneFragment,
  useSidebarSiteQuery,
  useSidebarFloorQuery,
} from '@/.generated/graphql';
import { useGate } from 'statsig-react';
import { useTranslation } from 'react-i18next';
import { TextMatchDisplay } from '@/components/primitives/TextMatchDisplay';
import { BuildingEditMenu } from './BuildingEditMenu';
import { SiteEditMenu } from './SiteEditMenu';
import { datadogRum } from '@datadog/browser-rum';
import { MenuFoldOutIcon } from '@/components/Icons';

export interface SidebarSpaceHierarchyProps {
  className?: string;
  onSidebarOpenClose: (open: boolean) => void;
}
import { Dispatch, SetStateAction } from 'react';

export const FilterContext = createContext<{
  filterValue: string;
  isSearchActive: boolean;
  setSearchActive: Dispatch<SetStateAction<boolean>>;
  setFilterValue: Dispatch<SetStateAction<string>>;
}>({
  filterValue: '',
  isSearchActive: false,
  setSearchActive: () => {},
  setFilterValue: () => {},
});

export type SelectedMatchedItemsType = {
  id: string;
  type: 'site' | 'building' | 'floor' | 'room' | 'zone';
  matchesFilter: boolean;
  selected: boolean;
};

const sortFn = (item1: { name: string; id: string }, item2: { name: string; id: string }) =>
  item1.name.localeCompare(item2.name) === 0
    ? item1.id.localeCompare(item2.id)
    : item1.name.localeCompare(item2.name);

export function SidebarSpaceHierarchy({
  className,
  onSidebarOpenClose,
}: SidebarSpaceHierarchyProps) {
  const [filterValue, setFilterValue] = useState('');
  const { value: sitesBuildingsEnabled } = useGate('sites_and_buildings');
  const { data: floorData } = useSidebarFloorQuery({ skip: sitesBuildingsEnabled });
  const { data: siteData } = useSidebarSiteQuery({
    skip: !sitesBuildingsEnabled,
    fetchPolicy: 'network-only',
  });

  const { spaceId: selectedFloorId, roomId, zoneId } = useSRZId();
  const [selectedMatchedItems, setSelectedMatchedItems] = useState<SelectedMatchedItemsType[]>([]);
  const [anyMatchFound, setAnyMatchFound] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);

  useEffect(() => {
    const lowerCaseFilterValue = filterValue.toLowerCase();
    const selectedMatchedItemsArray: Array<any> = [];
    let matchFound = false;
    const addItemIfMatchesOrSelected = (item: any, type: string, isSelected: boolean) => {
      const itemMatchesFilter = isSearchActive
        ? item.name.toLowerCase().includes(lowerCaseFilterValue)
        : false;
      if (itemMatchesFilter || isSelected) {
        selectedMatchedItemsArray.push({
          id: item.id,
          type,
          matchesFilter: itemMatchesFilter,
          selected: isSelected,
        });
      }
      return itemMatchesFilter;
    };

    siteData?.sites.data.forEach((site) => {
      matchFound = addItemIfMatchesOrSelected(site, 'site', false) || matchFound;

      site.buildings.forEach((building) => {
        matchFound = addItemIfMatchesOrSelected(building, 'building', false) || matchFound;

        building.floors.forEach((floor) => {
          const floorIsSelected = floor.id === selectedFloorId && !roomId && !zoneId;
          matchFound = addItemIfMatchesOrSelected(floor, 'floor', floorIsSelected) || matchFound;

          floor.rooms?.forEach((room) => {
            const roomIsSelected = room.id === roomId && !zoneId;
            matchFound = addItemIfMatchesOrSelected(room, 'room', roomIsSelected) || matchFound;
          });

          floor.zones?.forEach((zone) => {
            const zoneIsSelected = zoneId === zone.id;
            matchFound = addItemIfMatchesOrSelected(zone, 'zone', zoneIsSelected) || matchFound;
          });
        });
      });
    });

    setSelectedMatchedItems(selectedMatchedItemsArray);
    setAnyMatchFound(matchFound);
  }, [filterValue, siteData, selectedFloorId, roomId, zoneId, isSearchActive, anyMatchFound]);

  return (
    <FilterContext.Provider
      value={{ filterValue, isSearchActive, setSearchActive, setFilterValue }}
    >
      <div className={clsx('flex flex-col h-[90vh] min-h-0', className)} data-id="sidebar-nav">
        <SidebarControls onSidebarOpenClose={onSidebarOpenClose} />
        <div className="flex flex-col w-full pr-3 pt-3 group overflow-y-auto flex-grow">
          {!isSearchActive || anyMatchFound || filterValue === '' ? (
            sitesBuildingsEnabled ? (
              [...(siteData?.sites?.data ?? [])]
                .sort(sortFn)
                .map((site) => (
                  <SidebarSiteItem key={site.id} site={site} matches={selectedMatchedItems} />
                ))
            ) : (
              [...(floorData?.floors.data ?? [])]
                .sort(sortFn)
                .map((floor) => (
                  <SidebarFloorItem key={floor.id} floor={floor} matches={selectedMatchedItems} />
                ))
            )
          ) : (
            <NullSearch />
          )}
        </div>
        <div>
          <hr className="border-t border-gray-200 m-4" />

          <div className="flex justify-end m-4">
            {' '}
            <CreateSpaceButton data-id="create-new-floor" />
          </div>
        </div>
      </div>
    </FilterContext.Provider>
  );
}
interface SidebarControlsProps {
  onSidebarOpenClose: (open: boolean) => void;
}
const SidebarControls: React.FC<SidebarControlsProps> = ({ onSidebarOpenClose }) => {
  const onClick = () => {
    onSidebarOpenClose(false);
  };
  const { setSearchActive, setFilterValue, filterValue } = useContext(FilterContext);
  return (
    <div className="flex gap-2 px-3 py-2 items-center">
      <MenuFoldOutIcon
        onClick={onClick}
        className="w-4 h-4 m-2 text-product-gray900 cursor-pointer"
        color="black"
      />
      <SearchInput
        data-id="search-input"
        value={filterValue}
        onFocus={() => {
          setSearchActive(true);
          datadogRum.addAction('Activate sidebar search', { source: 'sites-and-buildings' });
        }}
        onClear={() => {
          setSearchActive(false);
          setFilterValue('');
        }}
        onChange={(ev) => {
          setFilterValue(ev.target.value);
        }}
      />
    </div>
  );
};

export function SidebarSiteItem({
  site,
  matches,
}: {
  site: SidebarSiteItemSiteFragment;
  matches: SelectedMatchedItemsType[];
}) {
  const { filterValue } = useContext(FilterContext);

  const blockDelete = site.buildings.some(
    (building) => building.floors && building.floors.length > 0,
  );
  const matchesFilter =
    !!filterValue && site.name.toLowerCase().includes(filterValue.toLowerCase());

  const allIds = new Set([
    ...site.buildings.map((building) => building.id),
    ...site.buildings.flatMap((building) => building.floors.map((floor) => floor.id)),
    ...site.buildings.flatMap((building) =>
      building.floors.flatMap((floor) => floor.rooms?.map((room) => room.id) || []),
    ),
    ...site.buildings.flatMap((building) =>
      building.floors.flatMap((floor) => floor.zones?.map((zone) => zone.id) || []),
    ),
  ]);

  const [selectedMatchFound, filterMatchFound] = calculateMatches(allIds, matches);
  return (
    <SidebarSection
      selectable={false}
      alwaysVisible
      highlighted={matchesFilter}
      toggleable={false}
      childHighlighted={filterMatchFound}
      childSelected={selectedMatchFound}
      actionContent={<SiteEditMenu id={site.id} blockDelete={blockDelete} />}
      title={
        <div className="px-2 flex items-center justify-between">
          <span
            className="min-w-0 overflow-hidden text-ellipsis text-product-gray500 font-semibold leading-5"
            data-id="sidebar-nav-site-item"
          >
            Site.{' '}
            <TextMatchDisplay
              text={site.name}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
          </span>
        </div>
      }
    >
      {[...(site.buildings ?? [])]
        .sort(sortFn)
        ?.map((building) => (
          <SidebarBuildingItem key={building.id} building={building} matches={matches} />
        ))}
    </SidebarSection>
  );
}

function NullSearch() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col items-center gap-2 px-3 py-2 w-[216px]">
        <span className="text-xs font-semibold">{t('noResults')}</span>
        <span className="text-[10px] text-center">{t('searchAction')}</span>
      </div>
    </div>
  );
}

function SidebarBuildingItem({
  building,
  matches,
}: {
  building: SidebarBuildingItemBuildingFragment;
  matches: SelectedMatchedItemsType[];
}) {
  const { filterValue } = useContext(FilterContext);
  const matchesFilter =
    !!filterValue && building.name.toLowerCase().includes(filterValue.toLowerCase());
  const blockDelete = building.floors && building.floors.length > 0;

  const allIds = new Set([
    ...building.floors.map((floor) => floor.id),
    ...building.floors.flatMap((floor) => floor.rooms?.map((room) => room.id) || []),
    ...building.floors.flatMap((floor) => floor.zones?.map((zone) => zone.id) || []),
  ]);
  const [selectedMatchFound, filterMatchFound] = calculateMatches(allIds, matches);

  return (
    <SidebarSection
      selectable={false}
      alwaysVisible
      childSelected={selectedMatchFound}
      childHighlighted={filterMatchFound}
      actionContent={<BuildingEditMenu id={building.id} blockDelete={blockDelete} />}
      title={
        <>
          <span
            className="min-w-0 overflow-hidden text-ellipsis text-sm"
            data-id="sidebar-nav-building-item"
          >
            <TextMatchDisplay
              text={building.name}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
          </span>
        </>
      }
      highlighted={matchesFilter}
    >
      {[...(building.floors ?? [])]
        .sort(sortFn)
        ?.map((floor) => <SidebarFloorItem key={floor.id} floor={floor} matches={matches} />)}
    </SidebarSection>
  );
}

function SidebarFloorItem({
  floor,
  matches,
  ...filterProps
}: {
  floor: SidebarFloorItemFloorFragment;
  allowMultiSelect?: boolean;
  matches: SelectedMatchedItemsType[];
}) {
  const { setSearchActive, filterValue, setFilterValue } = useContext(FilterContext);
  const { spaceId: selectedFloorId, roomId, zoneId } = useSRZId();
  const selected = selectedFloorId === floor.id && !roomId && !zoneId;
  const history = useHistory();

  const matchesFilter =
    !!filterValue && floor.name.toLowerCase().includes(filterValue.toLowerCase());

  const allIds = useMemo(
    () =>
      new Set([
        ...(floor.rooms?.map((room) => room.id) || []),
        ...(floor.zones?.map((zone) => zone.id) || []),
      ]),
    [floor.rooms, floor.zones],
  );

  const [selectedMatchFound, filterMatchFound] = calculateMatches(allIds, matches);

  return (
    <SidebarSection
      selected={selected}
      childHighlighted={filterMatchFound}
      childSelected={selectedMatchFound}
      onSelectedChange={() => {
        history.push(`/?spaceId=${floor.id}`);
        setSearchActive(false);
        setFilterValue('');
      }}
      title={
        <>
          <Icon name="layer" />
          <span
            className="min-w-0 overflow-hidden text-ellipsis text-sm"
            data-id="sidebar-nav-floor-item"
          >
            <TextMatchDisplay
              text={floor.name}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
          </span>
        </>
      }
      highlighted={matchesFilter}
    >
      {[...(floor.rooms ?? [])].sort(sortFn)?.map((room) => {
        // TODO: support Room.zones in GraphQL so we don't have to manually associate them here
        const zonesForRoom = floor.zones?.filter((zone) => zone.roomId === room.id) ?? [];
        return <SidebarRoomItem room={room} zones={zonesForRoom} key={room.id} {...filterProps} />;
      })}
      {[...(floor.zones ?? [])]
        ?.filter((zone) => !zone.roomId)
        ?.sort(sortFn)
        ?.map((zone) => <SidebarZoneItem zone={zone} key={zone.id} {...filterProps} />)}
    </SidebarSection>
  );
}

function SidebarRoomItem({
  room,
  zones,
}: {
  room: SidebarRoomItemRoomFragment;
  zones: SidebarZoneItemZoneFragment[];
}) {
  const { roomId, zoneId } = useSRZId();
  const selected = roomId === room.id && !zoneId;
  const history = useHistory();
  const { filterValue, setSearchActive, setFilterValue } = useContext(FilterContext);
  const matchesFilter =
    !!filterValue && room.name.toLowerCase().includes(filterValue.toLowerCase());

  return (
    <SidebarSection
      selected={selected}
      onSelectedChange={() => {
        history.push(`/?spaceId=${room.floorId}&roomId=${room.id}`);
        setSearchActive(false);
        setFilterValue('');
      }}
      title={
        <>
          <Icon name="room" />
          <span
            className="min-w-0 overflow-hidden text-ellipsis text-sm"
            data-id="sidebar-nav-room-item"
          >
            <TextMatchDisplay
              text={room.name}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
          </span>
        </>
      }
      highlighted={matchesFilter}
    >
      {[...(zones ?? [])]
        ?.sort(sortFn)
        ?.map((zone) => <SidebarZoneItem zone={zone} key={zone.id} />)}
    </SidebarSection>
  );
}

function SidebarZoneItem({ zone }: { zone: SidebarZoneItemZoneFragment }) {
  const { zoneId } = useSRZId();
  const selected = zoneId === zone.id;
  const history = useHistory();
  const { filterValue, setSearchActive, setFilterValue } = useContext(FilterContext);
  const matchesFilter =
    !!filterValue && zone.name.toLowerCase().includes(filterValue.toLowerCase());

  return (
    <SidebarSection
      selected={selected}
      onSelectedChange={() => {
        history.push(`/?spaceId=${zone.floorId}&zoneId=${zone.id}`);
        setSearchActive(false);
        setFilterValue('');
      }}
      title={
        <>
          <Icon name="zone" />
          <span
            className="min-w-0 overflow-hidden text-ellipsis text-sm"
            data-id="sidebar-nav-zone-item"
          >
            <TextMatchDisplay
              text={zone.name}
              match={filterValue}
              variant="highlight"
            ></TextMatchDisplay>
          </span>
        </>
      }
      highlighted={matchesFilter}
    />
  );
}

function calculateMatches(allIds: Set<string>, matches?: SelectedMatchedItemsType[]) {
  let selectedFound = false;
  let filterFound = false;

  matches?.some((match) => {
    const matchExists = allIds.has(match.id);

    if (matchExists) {
      if (match.selected) {
        selectedFound = true;
      }
      if (match.matchesFilter) {
        filterFound = true;
      }
    }

    return selectedFound && filterFound; // Stop iteration if both are found
  });

  return [selectedFound, filterFound];
}
SidebarZoneItem.fragments = {
  Zone: gql`
    fragment SidebarZoneItemZone on Zone {
      id: zone_id
      name
      floorId: floor_id
      roomId: room_id
    }
  `,
};

SidebarRoomItem.fragments = {
  Room: gql`
    fragment SidebarRoomItemRoom on Room {
      id: room_id
      name
      floorId: floor_id
    }
  `,
};

SidebarFloorItem.fragments = {
  Floor: gql`
    fragment SidebarFloorItemFloor on Floor {
      id: floor_id
      name
      rooms {
        id: room_id
        ...SidebarRoomItemRoom
      }
      zones {
        id: zone_id
        ...SidebarZoneItemZone
      }
    }
    ${SidebarRoomItem.fragments.Room}
    ${SidebarZoneItem.fragments.Zone}
  `,
};

SidebarBuildingItem.fragments = {
  Building: gql`
    fragment SidebarBuildingItemBuilding on Building {
      id
      name
      floors {
        id: floor_id
        ...SidebarFloorItemFloor
      }
    }
    ${SidebarFloorItem.fragments.Floor}
  `,
};

SidebarSiteItem.fragments = {
  Site: gql`
    fragment SidebarSiteItemSite on Site {
      id
      name
      buildings {
        id
        ...SidebarBuildingItemBuilding
      }
    }
    ${SidebarBuildingItem.fragments.Building}
  `,
};

SidebarSpaceHierarchy.queries = {
  Floors: gql`
    query SidebarFloor {
      floors {
        data {
          id: floor_id
          ...SidebarFloorItemFloor
        }
      }
    }
    ${SidebarFloorItem.fragments.Floor}
  `,
  Sites: gql`
    query SidebarSite {
      sites {
        data {
          id
          ...SidebarSiteItemSite
        }
      }
    }
    ${SidebarSiteItem.fragments.Site}
  `,
};
