import { FC, memo, useCallback, useRef, useState, useEffect } from 'react';
import { useInterval, useTimezone } from '@/hooks';
import { useDateRangePickerState } from '@react-stately/datepicker';
import { useDateRangePicker } from '@react-aria/datepicker';
import { today, parseAbsolute, isSameDay, ZonedDateTime, now } from '@internationalized/date';
import { I18nProvider } from 'react-aria';
import { PopoverButtonAlternate } from './PopoverButtonAlternate';
import { RangeCalendar } from './RangeCalendar';
import { DateField } from './DateField';
import { useDispatch } from 'react-redux';
import { CHART_QUICK_RANGE_BUTTON_LABELS_ALTERNATE, EDateRangePickerType } from './Constant';
import { QuickRangeButtonAlternate } from './QuickRangeButtonAlternate';
import { isQuickRangeEdited, getNewDateRange } from './utility';
import { DateValue } from '@react-types/calendar';
import { AriaDateRangePickerProps } from '@react-types/datepicker';
import dayjs from 'dayjs';
import { convertTZString } from '@/utils/common';
import CloseIcon from '@/components/Icons/Close';
import { useTranslation } from 'react-i18next';
import { DropdownContent, DropdownRoot } from '@/components/primitives/Dropdown/Dropdown';
import { useTime } from '@/redux/hooks';
import {
  TIME_RANGE_MODE,
  updateTimeRange,
  updateRangeMode,
  getCurrentTimeState,
} from '@/redux/reducers/time';
import { datadogRum } from '@datadog/browser-rum';

export interface IDateRangePickerAlternate extends AriaDateRangePickerProps<DateValue> {
  type: EDateRangePickerType;
  id: string;
  hAlignment?: 'left' | 'right' | 'center';
  vAlignment?: 'top' | 'bottom' | 'middle';
  status?: 'error' | 'normal';
}

export const DateRangePickerAlternate: FC<IDateRangePickerAlternate> = memo(
  ({ type, id, status = 'normal', ...rest }) => {
    const tz = useTimezone();
    // useTime should not return null so here is just a precaution.
    const time = useTime() || getCurrentTimeState(tz);

    // The biggest difference here is that the new/alternate date picker does not consider type, i.e. charts or detections.
    // It should be able to control time all the same way

    const {
      timeRangeMode,
      timeRange: { startEpoch, endEpoch },
    } = type === EDateRangePickerType.CHARTS ? time.chart : time.detection;

    const {
      t,
      i18n: { language: currentLang },
    } = useTranslation();
    const dispatch = useDispatch();

    //original code: get current time
    const defaultNowTime = parseAbsolute(
      dayjs(time?.currentDate || new Date())
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ssZ'),
      tz,
    ).set({ second: 0, millisecond: 0 });

    const [nowTime, setNowTime] = useState(defaultNowTime);

    //original code: default state
    const state = useDateRangePickerState({
      ...rest,
      defaultValue: {
        start: (
          (time && parseAbsolute(dayjs(startEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz)) ||
          defaultNowTime
        ).set({
          second: 0,
          millisecond: 0,
        }),
        end:
          (time &&
            parseAbsolute(dayjs(endEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
              second: 0,
              millisecond: 0,
            })) ||
          defaultNowTime,
      },
      hideTimeZone: true,
      shouldCloseOnSelect: false,
    });

    const { value: rangeValue, setValue } = state;

    //original code: reset date/time set when the timeRange is updated
    useEffect(() => {
      const { startEpoch, endEpoch } = time.chart.timeRange;
      const newStart = parseAbsolute(dayjs(startEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
        second: 0,
        millisecond: 0,
      });
      const newEnd = parseAbsolute(dayjs(endEpoch).format('YYYY-MM-DDTHH:mm:ssZ'), tz).set({
        second: 0,
        millisecond: 0,
      });
      setValue({ start: newStart, end: newEnd });
    }, [time.chart.timeRange]);

    //original code: set custom date range
    useEffect(() => {
      if (isQuickRangeEdited(rangeValue, nowTime, timeRangeMode, currentLang)) {
        dispatch(
          updateRangeMode({
            mode: TIME_RANGE_MODE.CUSTOM,
            type,
          }),
        );
      }
    }, [rangeValue, nowTime, timeRangeMode, currentLang, dispatch]);

    //original code: limit picker to one year
    const ref = useRef<Element>(null);
    const { startFieldProps, endFieldProps, calendarProps } = useDateRangePicker(
      {
        ...rest,
        minValue: today(tz).subtract({ years: 1 }),
        maxValue: nowTime,
      },
      state,
      ref,
    );

    // Update nowTime every 30 seconds,
    // difference from original code is that this block doesn't reset the time to the last 5 minutes, as it's not an option
    const updateNowtime = useCallback(() => {
      const newNowTime = now(tz).set({ second: 0, millisecond: 0 });
      setNowTime(newNowTime);
    }, [timeRangeMode, tz]);

    useInterval(30000, updateNowtime, []);

    //sets the new time range mode on select, and sends the new range to the global time
    const onTimeRangeModeChange = (nextTimeRangeMode: number) => {
      state.setValue(getNewDateRange(nowTime, nextTimeRangeMode, currentLang));
      const newDateRange = getNewDateRange(nowTime, nextTimeRangeMode, currentLang);
      const startTimeNew = newDateRange.start.toDate('').getTime();
      const endTimeNew = newDateRange.end.toDate('').getTime();

      dispatch(
        updateRangeMode({
          mode: nextTimeRangeMode,
          type,
        }),
      );
      dispatch(
        updateTimeRange({
          range: {
            startEpoch: startTimeNew,
            endEpoch: endTimeNew,
          },
          type,
        }),
      );
    };

    //sets custom range
    const applyCustomRange = () => {
      state.setOpen(false);
      datadogRum.addAction('Custom date range selected', {
        source: 'datepicker-new',
      });
      dispatch(
        updateTimeRange({
          range: {
            startEpoch: rangeValue.start.toDate(tz).getTime(),
            endEpoch: rangeValue.end.toDate(tz).getTime(),
          },
          type,
        }),
      );
    };

    const utcOffset = `UTC ${dayjs().tz(tz).utcOffset() / 60}:00`;
    const startTimeDisplay =
      dayjs(rangeValue.start.toDate(tz)).format('MMMM D, YYYY, HH:mm') + ' ' + utcOffset;
    const endTimeDisplay =
      dayjs(rangeValue.end.toDate(tz)).format('MMMM D, YYYY, HH:mm') + ' ' + utcOffset;

    return (
      <div className="relative inline-flex flex-row justify-self-end" id={id}>
        {/* Another big difference here is that the quick range buttons are 
        exposed at the top, rather than underneath the dropdown */}
        <DropdownRoot open={state.isOpen} onOpenChange={state.setOpen}>
          <div className="flex flex-wrap">
            <div className="flex-row flex">
              {/* uses the new list of range buttons */}
              {CHART_QUICK_RANGE_BUTTON_LABELS_ALTERNATE.map((label, index) => (
                // this new quick range button has very different styling than the original
                <QuickRangeButtonAlternate
                  type={type}
                  key={index}
                  label={t(label.label)}
                  id={label.index}
                  isSelected={timeRangeMode === label.index}
                  onSelected={() => {
                    datadogRum.addAction('Quick date range selected', {
                      source: 'datepicker-new',
                      range: label,
                    });
                    onTimeRangeModeChange(label.index);
                  }}
                  onClose={() => state.setOpen(false)}
                />
              ))}
              {/* also very different styling than the original  */}
              <PopoverButtonAlternate
                label={t('custom')}
                tz={tz}
                isSelected={timeRangeMode === 0}
                timeRangeMode={timeRangeMode}
                rangeValue={rangeValue}
                rangeLabels={CHART_QUICK_RANGE_BUTTON_LABELS_ALTERNATE.map((item) => item.label)}
                status={status}
              />
            </div>
            <div className="text-product-gray700 text-xs flex flex-row mt-2 ml-2">
              <div className="px-2"> {startTimeDisplay}</div> -
              <div className="px-2"> {endTimeDisplay}</div>
            </div>
          </div>
          <DropdownContent align="end" side="bottom">
            {/* the calendar dropdown is essentially the same, minus a couple minor styling updates */}
            <div className="py-3 pl-5 text-xs font-semibold leading-3 border-solid border-b-1 border-b-gray-200 flex-shrink-0">
              {t('selectTimeRange')}
            </div>
            <div className="flex flex-grow">
              <div className="max-h-full w-[375px] bg-gray-100 px-2 pt-2 overflow-y-auto">
                <div className="rounded-lg bg-white">
                  <I18nProvider locale={currentLang}>
                    <RangeCalendar
                      tz={tz}
                      {...{
                        ...calendarProps,
                        defaultFocusedValue: rangeValue.end as ZonedDateTime,
                        timeRangeMode: timeRangeMode,
                      }}
                      onChange={(value) => {
                        calendarProps.onChange?.(value);
                        // Needed since the calendar can update the time as well. Above function seems locked
                        // to only update the date.
                        state.setValue(value);
                      }}
                    />
                  </I18nProvider>
                  <div className="ml-8 mb-1 text-xs font-normal text-gray-500">{t('from')}</div>
                  <div className="mb-3 flex justify-center gap-0.5 text-xs font-normal">
                    <DateField {...startFieldProps} />
                  </div>
                  <div className="ml-8 mb-1 text-xs font-normal text-gray-500">{t('until')}</div>
                  <div className="flex justify-center gap-0.5 text-xs font-normal">
                    <DateField
                      {...{
                        ...endFieldProps,
                        untilNow:
                          isSameDay(nowTime, rangeValue.end as ZonedDateTime) &&
                          timeRangeMode !== TIME_RANGE_MODE.CUSTOM,
                      }}
                    />
                  </div>
                  <div className="h-5"></div>
                </div>
                <button
                  tabIndex={0}
                  className="my-3 flex cursor-pointer"
                  onClick={() => {
                    state.setOpen(false);
                    onTimeRangeModeChange(
                      timeRangeMode === TIME_RANGE_MODE.CUSTOM
                        ? TIME_RANGE_MODE.CUSTOM
                        : TIME_RANGE_MODE.TODAY,
                    );
                  }}
                >
                  <div className="py-0.5">
                    <CloseIcon />
                  </div>
                  <div className="ml-1.5 cursor-pointer text-xs font-normal text-gray-600">
                    {t('reset')}
                  </div>
                </button>
              </div>
            </div>
            <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
            <div className="flex h-12.5 items-center justify-between">
              <div role="note" className="ml-2 text-xxs font-semibold leading-4 text-blue-gray">
                {`${convertTZString(tz, currentLang)}, ${utcOffset}`}
              </div>
              <button
                className="mr-2.5 my-2 h-8 w-32 rounded-sm bg-product-red500 text-xs font-semibold leading-4 text-white hover:bg-product-red400 disabled:bg-gray-100 disabled:text-gray-300"
                onClick={applyCustomRange}
              >
                {t('applyTimeRange')}
              </button>
            </div>
          </DropdownContent>
        </DropdownRoot>
      </div>
    );
  },
);
